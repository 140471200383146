<template>
  <v-dialog width="600">
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-if="
          $store.getters['auth/isLoggedIn'] &&
          $route.name === 'appointmentView' &&
          edit !== false
        "
        style="position: absolute; right: 95px"
        small
        v-on="on"
      >
        <v-icon color="primary" large> mdi-plus-circle </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ $tc("customer", 2) }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text class="py-0">
        <v-row>
          <v-spacer />
          <AddExtraTattooer
            :appointment="appointment"
            @update="$emit('update')"
          />
        </v-row>
      </v-card-text>
      <v-data-table :items="extra_tattooers" :headers="headers">
        <template v-slot:item.phone="{ item }">
          <span v-if="item.user.phone == null">-</span
          ><span>{{ item.user.phone }}</span></template
        >
        <template v-slot:item.last_name="{ item }">
          {{ item.user.last_name || "-" }}
        </template>
        <template v-slot:item.email="{ item }">
          <v-tooltip v-if="item.user.email" bottom>
            <template v-slot:activator="{ on }">
              <a
                @click="
                  $router.push({
                    name: 'email',
                    params: { email: item.user.email },
                  })
                "
                v-on="on"
                v-text="item.user.email"
              />
            </template>
            {{ item.user.email }}
          </v-tooltip>
          <span v-else> - </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            icon
            @click="
              $router.push({ name: 'tattooerEdit', params: { id: item.id } })
            "
            v-if="$store.getters['auth/isLoggedIn'] && edit !== false"
            small
          >
            <v-icon small color="primary"> $edit </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ExtraTattooers",
  props: ["extra_tattooers"],

  components: {
    AddExtraTattooer: () => import("@/components/tattooer/AddExtraTattooer"),
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("first_name"),
          align: "center",
          sortable: false,
          value: "user.first_name",
        },
        {
          text: this.$t("last_name"),
          align: "center",
          sortable: false,
          value: "last_name",
        },
        {
          text: this.$t("email"),
          align: "center",
          sortable: false,
          value: "email",
        },
        {
          text: this.$t("phone"),
          align: "center",
          sortable: false,
          value: "phone",
        },
        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
};
</script>